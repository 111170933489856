.apps{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.apps__radio{
    display: none;
}

.apps__heading{
    flex-grow: 1;
    text-align: center;
    background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
    background-color: var(--nuetral-light);
    color: var(--nuetral-light-transparent);
    padding: 2rem 0.5rem;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    font-size: 0;
    cursor: pointer;
    transition: color 0.5s;
}
/*to get rid of extra space under inline-block / inline elements*/
.apps__heading i{
    font-size: 2.3rem;
}

.apps__radio:checked+.apps__heading{
    color: var(--nuetral-light);
}



.apps__content{
    width: 100%;
    order: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3rem 1rem;
    margin: -0.5rem;
    visibility: hidden;
    position: absolute;
    opacity: 0;
    transform: translateY(-50px);
    transition: transform 0.5s , opacity 0.5s;
}

.apps__radio:checked+.apps__heading+.apps__content{
    opacity: 1;
    position: initial;
    visibility: visible;
    transform: translateY(0);
}

.apps__content::after {
    content: "";
    flex: auto;
}

.apps__app{
    flex-basis: calc(33.33% - 1rem);
    margin: 0.5rem;
    background-color: var(--primary-color-very-light);
    border-radius: 10%;
    padding: 1rem;
    box-shadow: 0 1px 5px var(--primary-color-dark);
    text-decoration: none;
    color: var(--nuetral-dark);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.apps__photo{
    max-width: 60%;
    border-radius: 20%;
    margin-bottom: 1rem;
    border: 1px solid var(--nuetral-dark);
}



.apps__line{
    --translate-x:-100%;
    --top: -1000px;
    --width: 0;
    position: absolute;
    z-index: 10;
    top: var(--top);
    left: 100%;
    transform: translateX(var(--translate-x));
    width: var(--width);
    height: 5px;
    background-color: var(--nuetral-light);
    border-bottom: 1px solid var(--primary-color-very-dark);
    transition: transform 0.3s;
}

@media only screen and (max-width: 21.7em) {
    .apps__app {
        flex-basis: calc(50% - 1rem);
    }
}

@media only screen and (min-width: 37.5em) {
    .apps__content{
        padding: 3rem 2rem;
        margin: -1rem;
    }
    .apps__app {
        flex-basis: calc(25% - 2rem);
        margin: 1rem;
    }
}

@media only screen and (min-width: 62em) {

    .apps__content{
        margin: -1.5rem;
    }

    .apps__app {
        flex-basis: calc(20% - 3rem);
        margin: 1.5rem;
        font-size: 1.4rem;
    }

    .apps__photo{
        margin-bottom: 2rem;
    }

    .apps__heading{
        flex-grow: 1;
        text-align: center;
        background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
        background-color: var(--nuetral-light);
        color: var(--nuetral-light-transparent);
        padding: 2.5rem;
        font-size: 0;
        transition: color 0.5s;
    }

    /*to get rid of extra space under inline-block / inline elements*/
    .apps__heading i{
        font-size: 2.7rem;
    }

}