.tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tabs__radio {
    display: none;
}

.tabs__heading {
    --translateY :0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;
    z-index: 10;
    background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
    background-color: var(--primary-color-very-dark);
    color: var(--nuetral-light-transparent);
    border-top: 1px solid var(--primary-color-very-dark);
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    cursor: pointer;
    text-align: center;
    flex-grow: 1;
    padding: 2rem 0.5rem;
    font-size: 0;
    transform: translateY(var(--translateY));
    transition: transform 0.3s;
}

/*to get rid of extra space under inline-block / inline elements*/
.tabs__heading i {
    font-size: 2rem;
}


.tabs__configs{
    right:0;
}

.tabs__apps{
    left: 0;
}


.tabs__content {
    order: 1;
    width: 100%;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height 0s, opacity 1s;
}

.tabs__radio:checked + .tabs__heading + .tabs__content {
    opacity: 1;
    height: auto;
}



.tabs__radio:checked + .tabs__heading {
    color: var(--nuetral-light);
}



.tabs__line {
    --visibility: visible;
    --translate-x: -100%;
    --width: 0;
    position: fixed;
    z-index: 10;
    bottom:0;
    left: 100%;
    transform: translateX(var(--translate-x));
    width: var(--width);
    height: 5px;
    background-color: var(--nuetral-light);
    visibility: var(--visibility);
    transition: transform 0.3s , visibility 0.1s;
}

@media only screen and (min-width: 62em ) {
    .tabs__heading {
        position: initial;
        transform: none;
        padding: 2.5rem;
    }

    .tabs__heading i:before {
        font-size: 2.3rem;
    }

    .tabs__line{
        --top: -1000px;
        position: absolute;
        top: var(--top);
        visibility: visible;
    }
}