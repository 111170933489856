@font-face {
  font-family: 'icomoon';
  src:      url('fonts/icomoon.woff?csfxki') format('woff'),
    url('fonts/icomoon.ttf?csfxki') format('truetype'),
    url('fonts/icomoon.svg?csfxki#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-v2ray:before {
  content: "\e901";
}
.icon-trash:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e902";
}
.icon-tag:before {
  content: "\e903";
}
.icon-list:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-gear:before {
  content: "\e906";
}
.icon-app:before {
  content: "\e907";
}
.icon-copy:before {
  content: "\e908";
}
.icon-mac:before {
  content: "\e909";
}
.icon-android:before {
  content: "\e90a";
}
.icon-windows:before {
  content: "\e90b";
}
.icon-ios:before {
  content: "\e90c";
}
.icon-linux:before {
  content: "\e90d";
}
.icon-apps:before {
  content: "\e90e";
}
