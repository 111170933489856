@font-face {
    font-family: 'iranyekan';
    src: url("../fonts/iranyekanwebregularfanum.woff2") format("woff2"),
    url("../fonts/iranyekanwebregularfanum.woff") format("woff"),
    url("../fonts/iranyekanwebregularfanum.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'iranyekan';
    src: url("../fonts/iranyekanwebmediumfanum.woff2") format("woff2"),
    url("../fonts/iranyekanwebmediumfanum.woff") format("woff"),
    url("../fonts/iranyekanwebmediumfanum.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
