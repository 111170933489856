
/*********** button ******************/
.btn {
    position: relative;
    cursor: pointer;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    font-family: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 1.4rem;
    border: none;
    box-shadow: 0 1px 5px var(--primary-color-dark);
    /*border: 1px solid var(--primary-color-dark);*/
    /*background-image: linear-gradient(180deg, var(--primary-color-medium) , var(--primary-color-dark));*/
    /*background-image: linear-gradient(180deg, var(--nuetral-dark-light), var(--nuetral-dark));*/
    /*background-image: linear-gradient(180deg, var(--nuetral-light) , var(--primary-color-very-light));*/
    background-color: var(--primary-color-very-light);
    color: var(--nuetral-dark);
    border-radius: 50%;
    font-size: 1.6rem;
    /*transform-style: preserve-3d;*/
    /*transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);*/
}

/*to get rid of extra space under inline-block / inline elements*/
.btn i{
    float: left;
}
/*********** button ******************/


.time{
    background-image: linear-gradient(180deg, var(--nuetral-dark-light), var(--nuetral-dark));
    background-color: var(--nuetral-dark);
    color: var(--nuetral-light);
    font-family: 'iranyekan', 'sans-serif';
    font-size: 1.5rem;
    padding: 1.2rem;
    text-align: center;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    text-align: center;
}

thead tr {
    background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
    /*background-image: linear-gradient(180deg,  #525151, var(--nuetral-dark));*/
    background-color: var(--primary-color-dark);
    border-bottom: 1px solid var(--nuetral-dark);
}

th {
    padding: 2rem 0.5rem;
    font-size: 0;
    color: var(--nuetral-light);
}

th i{
    font-size: 2rem;
}

td{
    padding: 2rem 0.5rem;
}



tbody tr:nth-child(odd) {
    background-image: linear-gradient(180deg, var(--primary-color-very-light) , var(--primary-color-light));
    background-color: var(--primary-color-light);
    border-bottom: 1px solid var(--nuetral-light);

}

tbody tr:nth-child(even) {
    border-bottom: 1px solid var(--nuetral-light);
    background-color: var(--primary-color-very-light);
}

.configs__copy-head a{
    text-decoration: none;
    color: inherit;
}

.configs__number{
    font-family: 'iranyekan', 'sans-serif';
}

.configs__copy-btn{
    position: relative;
}

.configs__copy-btn::before{
    position: absolute;
    z-index: 10;
    content: "کپی شد";
    top: 100%;
    left: 50%;
    transform: translate(-50%, -2rem);
    background-color: var(--nuetral-dark);
    color: var(--primary-color-light);
    padding: 0.5em 1em;
    border-radius: 20px;
    font-family: 'iranyekan', 'sans-serif';
    font-weight: 400;
    font-size: 1rem;
    width: max-content;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s , visibility 0.3s, transform 0.3s;
}

.configs__copy-btn--copied::before{
    visibility: visible;
    transform: translate(-50%, 0.75rem);
    opacity: 1;
}

@media only screen and (min-width: 48em ) {
    .btn{
        font-size: 1.4rem;
    }

}

@media only screen and (min-width: 62em ) {

    td, th {
        padding: 2.5rem;
    }


    th i{
        font-size: 2.7rem;
    }
}


