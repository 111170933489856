html {
    font-size: 62.5%;
    --primary-color: #3680fa;
    --primary-color-light: #c4d8fa;
    --primary-color-very-light: #dce6f5;
    --primary-color-dark: #2666CF;
    --primary-color-very-dark: #174ea8;
    --nuetral-dark: #343434;
    --nuetral-dark-light: #525151;
    --nuetral-light: #ffffff;
    --nuetral-light-transparent: #ffffffaa
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    min-height: 100vh;
    color: var(--nuetral-dark);
    background-color: var(--nuetral-light);
}

.page {
    width: 100%;
    min-height: 100vh;
    background-color: var(--primary-color-very-light);
}

@media only screen and (min-width: 62em ) {
    body {
        font-size: 1.6rem;
    }

    .page {
        width: 90%;
        max-width: 140rem;
        margin: 0 auto;
        box-shadow: 0 0 10px var(--primary-color-dark);
    }
}
